import React, { useEffect, useState } from "react";
import "../../styles/discover-luxembourg.scss";
import { navigate } from "gatsby";
import routes from "../../components/constants/routes";

export default function DiscoverLuxembourg() {
  useEffect(() => {
    navigate(routes.DLAbout);
  }, []);
  return <></>;
}
