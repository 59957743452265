const routes = {
  Home: "/",
  About: "/about-us",
  Filmfestival: "/filmfestival",
  Discoverluxembourg: "/discover-luxembourg",
  contact: "/contact",
  FFAbout: "/filmfestival/about",
  FFScreenings: "/filmfestival/schedule",
  FFNews: "/filmfestival/news",
  FFPastSeason: "/filmfestival/past-screenings",
  FFCurrentSeason: "/filmfestival/current-screenings",
  FFRegister: "/filmfestival/register",
  FFMyprofile: "/filmfestival/my-profile",
  DLAbout: "/discover-luxembourg/about",
  DLCurrentEvent: "/discover-luxembourg/current-event",
  DLPastEvents: "/discover-luxembourg/past-events",
  DLNews: "/discover-luxembourg/news",
  DLRegister: "/discover-luxembourg/register",
  Page: "/page",
  PrivacyPolicy: "/privacy-policy",
  terms: "/terms-and-conditions",
};
export default routes;
